import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Metal2 from "../../static/img/G1N2.jpg"
import Metal3 from "../../static/img/G1N3.jpg"
import Metal4 from "../../static/img/G1N4.jpg"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="About" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="row">
            <div
              className="col-4"
              style={{
                padding: "0",
              }}
            >
              <img src={Metal2} />
            </div>
            <div
              className="col-4"
              style={{
                padding: "0",
              }}
            >
              <img src={Metal3} />
            </div>
            <div
              className="col-4"
              style={{
                padding: "0",
              }}
            >
              <img src={Metal4} />
            </div>
          </div>

          <h2 id="dynamic-styles">about IGL DESIGN</h2>
          <p>
            IGL DESIGN is a professional sculpture and art studio accepting
            commission works for public space and property development projects.
            We make outdoor sculptures to compliment the landscape design,
            indoor wall sculptures for lobby and paintings on canvas for
            property show units.
          </p>
          <p>
            We work closely with landscape architects and interior designers.
            Our clients include IOI Property Group, EcoWorld, NTP Properties,
            SkyWorld, Newfields Properties Group, Pavilion Property Development
            and SP Setia.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(
      relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
